import type { MoveThroughSignupStepProperties } from '@/src/ampli';

/**
 * Get the Amplitude event step name to track the user's progress through the sign-up flow.
 * @param registerStepName
 */
export function getSignupTrackingStep(
  registerStepName: string
): MoveThroughSignupStepProperties['stepNameFrom'] {
  switch (registerStepName) {
    case 'identity':
      return 'identity_completion';
    case 'confirmation':
      return 'otp_confirmation';
    case 'transport-card':
      return 'transport_card_adding';
    case 'payment-method':
      return 'payment_method_adding';
  }
}
